import React, { Fragment } from "react"
import './style.css'

const TableRow = (props) => (
  <>
    <table className={props.classes}>
      <thead>
        { props.headings.length > 0 &&
          <tr>
          { props.headings.map((heading) =>
            <th
              className={heading.split("|")[1]}
              colSpan={heading.split("|")[2]}
            >
              { heading.split("|")[0] }
            </th>
          )}
          </tr>
        }
      </thead>
      <tbody>
        { props.rows.length > 0 &&
           props.rows.map((row) =>
            <tr>
              { row.map((value) =>
                <td
                  className={ value.split("|")[1] }
                >
                  { value.split("|")[0] }
                </td>
              )}
            </tr>
          )
        }
      </tbody>
        { props.footer.length > 0 &&
          <tfoot>
          { props.footer.map((foot) =>
            <td 
              className={foot.split("|")[1]}
              colSpan={foot.split("|")[2]}>
                { foot.split("|")[0] }
            </td>
          )}
          </tfoot>
        }
    </table>
  </>
)

export default TableRow
