import React from "react"
import {Helmet} from "react-helmet";
import '../assets/css/main.css'
import StaticHeader from "../components/StaticHeader/StaticHeader"
import Footer from '../components/Footer/Footer'
import TableRow from "../components/TableRow/TableRow"
import NavBar from '../components/NavBar/NavBar'

// Add additional classes to the cells by separating cell value and class names with a pipe
const headings = [
  'Issue',
  'Reservation close*',
  'Newsstand On-sale'
]

// Add additional classes to the cells by separating cell value and class names with a pipe

const rows = [
    [
      'March/April 2024',
      'January 15, 2024',
      'February 27',
    ],[
      'May/June 2024',
      'March 18',
      'April 30',
    ],[
      'July/August 2024',
      'May 13',
      'June 25',
    ],[
      'September/October 2024',
      'July 15',
      'August 27',
    ],[
      'November/December 2024',
      'September 16',
      'October 29',
    ],[
      'January/February 2025',
      'November 11',
      'December 24',
    ]
]

const footer = [
  '* Materials are due five days after Reservation Close||3',
]

const TableClasses = "max-w-3xl mx-auto w-full mt-10 mb-10"

const Schedule = (props) => (
  <>
    <Helmet>
      <title>Foreign Affairs Mediakit - Schedule</title>
    </Helmet>
    <NavBar home={false} />
    <StaticHeader
      title="Schedule and Deadlines"
      subtitle=""
      intro="<p><em>Foreign Affairs</em> print magazine is released bi-monthly according to the schedule listed below. All digital products, like banner ads and newsletters sponsorships, are booked and released on a rolling basis.</p>"
    />
    <div className="max-w-screen-xl mx-auto px-5 table-row--3">
      <TableRow headings={headings} rows="" classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows} classes={TableClasses} footer={footer} />
    </div>
    <Footer />
  </>
)

export default Schedule
