import React from "react"
import '../../assets/css/main.css'
import './style.css'

const StaticHeader = (props) => (
  <header className="yellow-bg pb-12 static-header">
    <div className="max-w-screen-xl mx-auto px-5">
      <div className="lg:grid lg:grid-cols-2">
        <div>
          <h1 className="text-5xl">{props.title}</h1>
          <h2 className="neue-haas font-semibold tracking-wider pt-4">{props.subtitle}</h2>
        </div>
        <div dangerouslySetInnerHTML={{__html: props.intro}} className="freight-text static-header--intro"></div>
      </div>
    </div>
  </header>
)

export default StaticHeader
